import axios from 'axios';
import AlertService from '@/core/services/AlertService';

const CRUDModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    tableFilters: {},
    dataName: '',
    loading: false,
    crudLoading: false,
    loadingItem: false,
    editableId: null,
    isPagination: true,
    isCloseModal: false,
    apiPath: '',
    sort_column: null,
    sort_direction: null,
    pagination: {
      page: 1,
      total: 0,
      rowsPerPage: 15,
    },
  },
  mutations: {
    SET_DATA_TABLE(state, payload) {
      if(!payload.DataTable) return;
      if (payload.DataTable.length > 0 && !payload.DataTable[0]?.translations) {
        state.DataTable = payload.DataTable;
        return;
      }
      state.DataTable = payload.DataTable.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },
    SET_API_PATH(state, payload) {
      if (typeof payload == 'string') {
        state.apiPath = payload;
      } else {
        state.apiPath = payload?.api_path + '/' + payload?.id;
      }
    },
    SET_ITEM_EDITABLE(state, payload) {
      if (payload.itemEditable && !payload.itemEditable?.item?.translations) {
        state.itemEditable = payload.itemEditable;
        return;
      }

      let translations = {};
      payload.itemEditable?.item?.translations.map((lang) => {
        translations[lang.locale] = lang;
      });
      payload.itemEditable.item.translations = translations;
      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = '';
      state.tableFilters = {};
      state.loading = false;
      state.itemEditable = {};
      state.isPagination = true;
      state.sort_column = null;
      state.sort_direction = null;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 15,
      };
    },
    RESET_EDITABLE(state) {
      state.loading = false;
      state.itemEditable = {};
      state.editableId = null;
    },
    SET_EDITABLE_ID(state, id) {
      state.editableId = id;
    },
    SET_ROWS_PER_PAGE(state, payload) {
      state.pagination['rowsPerPage'] = payload;
      state.pagination['page'] = 1;
      state.pagination['pagesCount'] = Math.ceil(
        state.pagination.total / payload
      );
    },
    SET_TOTAL_NUM(state, payload) {
      state.pagination['total'] = payload;
      state.pagination['pagesCount'] = Math.ceil(
        payload / state.pagination.rowsPerPage
      );
    },
    SET_CURRENT_PAGE(state, payload) {
      state.pagination['page'] = payload;
    },
    SET_IS_PAGINATION(state, payload) {
      state.isPagination = payload;
    },
    SET_FILTERS(state, payload) {
      state.tableFilters = payload.filters;
    },
    SET_IS_CLOSE_MODAL(state, payload) {
      state.isCloseModal = payload;
    },
  },
  actions: {
    setApiPath({ commit }, payload) {
      commit('SET_API_PATH', payload);
    },
    setRowsPerPage({ commit, dispatch, state }, payload) {
      commit('SET_ROWS_PER_PAGE', payload);
      dispatch('getData', state.apiPath);
    },
    setSort({ state, dispatch }, sortObj) {
      state.sort_column = sortObj.sort_column;
      state.sort_direction = sortObj.sort_direction;
      dispatch('getData', state.apiPath);
    },
    setFilters({ commit, dispatch }, { filters, apiPath }) {
      commit('SET_FILTERS', { filters });
      dispatch('getData', apiPath);
    },
    setTotalNum({ commit }, payload) {
      commit('SET_TOTAL_NUM', payload);
    },
    setCurrentPage({ state, commit, dispatch }, payload) {
      commit('SET_CURRENT_PAGE', payload);
      dispatch('getData');
    },
    setIsPagination({ commit }, payload) {
      commit('SET_IS_PAGINATION', payload);
    },
    setDataName({ commit }, name) {
      commit('SET_DATA_Name', name);
    },
    setIsCloseModal({ commit }, payload) {
      commit('SET_IS_CLOSE_MODAL', payload);
    },
    setEditableId({ commit }, id) {
      commit('SET_EDITABLE_ID', id);
    },
    reset({ commit }) {
      commit('RESET_ALL');
    },
    resetEditable({ commit }) {
      commit('RESET_EDITABLE');
    },
    getData({ commit, state, dispatch }) {
      commit('SET_LOADING', true);

      commit('RESET_EDITABLE');
      state.tableFilters['pageSize'] = state.pagination.rowsPerPage;
      state.tableFilters['page'] = state.pagination.page;
      if (state.sort_column)
        state.tableFilters['sort_column'] = state.sort_column;
      if (state.sort_direction)
        state.tableFilters['sort_direction'] = state.sort_direction;

      return new Promise((resolve, reject) => {
        axios
          .get(`${state.apiPath}`, { params: state.tableFilters })
          .then((res) => {
            if (state.isPagination)
              commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);

            commit('SET_DATA_TABLE', {
              DataTable: res.data.result[state.dataName]?.data,
            });

            commit('SET_LOADING', false);

            resolve(res);
          })
          .catch((error) => {
            // console.log(error);
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },

    findItemById({ commit, state }, { id }) {
      commit('SET_LOADING_ITEM', true);
      axios
        .get(`${state.apiPath}/${id}`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: JSON.parse(JSON.stringify(res.data.result)),
          });
          // commit('SET_LOADING', false);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          // console.log(error);
          // commit('SET_LOADING', false);
        });
    },

    addItem({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.apiPath}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },


    updateItem({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${state.apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
            
            commit('RESET_EDITABLE');
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },
    mappingCategory({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },
    mappingProperty({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.apiPath}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },

    activeToggle({ commit, dispatch, state }, { id }) {
      // commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${state.apiPath}/active-toggle/${id}`)
          .then((response) => {
            dispatch('getData');

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteItem({ commit, dispatch, state }, { credentials }) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.apiPath}/${credentials}`)
          .then((response) => {
            dispatch('getData', state.apiPath);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            if(error?.response?.data?.message) {
              AlertService.error(error);
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default CRUDModule;
